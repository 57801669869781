import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {checkAuth, logout} from "@/services/auth.service";

const guardCheckAuth = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (!checkAuth()) {
        next('/login');
        return false;
    }
    next();
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/Private/Dashboard.vue'),
        beforeEnter: guardCheckAuth,
        meta: {layout: 'AppLayout'},
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('../views/Private/Statistics.vue'),
        beforeEnter: guardCheckAuth,
        meta: {layout: 'AppLayout'},
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Auth/Login.vue'),
        beforeEnter: (to, from, next) => {
            if (checkAuth()) {
                next({name: 'dashboard', replace: true});
            } else {
                next();
            }
        },
        meta: {layout: 'DefaultLayout'},
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => {
        },
        beforeEnter: (to, from, next) => {
            logout();
            window.location.reload();
        },
        meta: {
            layout: 'DefaultLayout',
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: {
            layout: 'DefaultLayout',
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
